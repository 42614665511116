.Skewed {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 150px;
  z-index: 0;
  transform-origin: top right;
  overflow: hidden;
  border-radius: 0 0 2em 2em;
}

.KaviHeader {
  height: 100%;
  border-radius: 0 0 2em 2em;
}

.SkewedContainer {
  height: 150px;
}

.SpgLogo {
  width: 100%;
  height: auto;
}

.MainTitle, .SubTitle {
  font-family: 'Playfair Display', serif;
  text-align: right;
  font-weight: 400;
  color: #543828;
  font-size: 18px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
/* Dark Mode  */
.DarkMode .MainTitle, .DarkMode .SubTitle, .DarkMode a {
  color: #DD715A;
}

.MainTitle h1 {
  font-weight: 800;
  font-size: 2.5em;
  font-style: italic;
  /* Brown Colour BELOW */
  /* color: #543828; */
  color: #EB9A98;
}

.ContentImage {
  width: 100%;
  object-fit: cover;
  border-radius: 3px 3px 0em 0;
  outline: 0;
  box-sizing: border-box;
  margin-top: 15px;
}

.SubText {
  margin: 10px 0;
  text-align: left;
  color: #2D2D2F;
  opacity: 0.45;
  font-family: 'Josefin Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.DarkMode .SubText {
  color: #EFCf83;
}

.Sec {
  margin: 1em 0;
  color: #2D2D2F;
}

.Reverse {
  flex-flow: column-reverse;
}

.LastSec {
  margin: 30px 0;
}

.Disclaimer {
  font-family: 'Josefin Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-size: 12; 
  opacity: 0.3;
  text-transform: uppercase;
  text-align: justify;
  text-align-last: center;
  color: #2D2D2F;
  font-size: 8px;
}

/* Dark Mode  */
.DarkMode .Disclaimer{
  color: #F7EBD4;
}

/* Dark Mode  */
.DarkMode .Sec {
  margin-top: 30px;
  color: #F7EBD4;
}

.SecText {
  text-align: left;
}

.SecText h3 {
  font-family: 'Josefin Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 200;
  font-size: 24px;;
}

.SecText p {
  font-family: 'Playfair Display', serif;
  text-align: justify;
  font-weight: 400;
}

.SecText p + p {
  margin-top: 30px;
}

/* Small Tablet */
@media screen and (min-width: 25em) {
  .MainTitle h1 {
    margin: 0;
    font-size: 3.2em;
  }

  .Skewed {
    height: 200px;
  }

  .SkewedContainer {
    height: 200px;
  }
}

/* Mobile Only */
@media screen and (max-width: 40em) {
  .MobileReverse {
    flex-flow: column-reverse;
  }
}

/* Tablet */
@media screen and (min-width: 40em) {
  
  .SecText h3 {
    margin-top: 0;
  }
  
  .Skewed {
    height: 275px;
  }

  .SkewedContainer {
    height: 275px;
  }

  .MainTitle h1 {
    font-size: 5em;
  }

}

/* Desktop */
@media screen and (min-width: 64em) {
  .Skewed {
    height: 350px;
  }

  .SkewedContainer {
    height: 350px;
  }

  .Reverse {
    flex-flow: row-reverse;
  }
}