/* Light Mode */
.ChapterNumberEnglish {
  font-family: 'Josefin Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #EB9A98;
  text-align: left;
}


.ChapterNameTranslit, .TitleEnglish {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  font-weight: 800;
  color: #543828;
  text-align: left;
}
/* Dark Mode */
.DarkMode .TitleEnglish {
  color: #F7EBD4;
}

.Summary {
  font-family: 'Cormorant Garamond', serif;
  font-weight: 300;
  letter-spacing: 0.3px;
  color: #2D2D2F;
  font-size: 1.4em;
  text-align: justify;
  text-align-last: end;
  margin: 8px 0;
}

/* Dark Mode */
.DarkMode .Summary {
  color: #F7EBD4;
}
