/* color: #F7EBD4; => Dark Grey */
/* color: #2D2D2F; => Dark Grey */
/* color: ##EB9A98; => Pink */

/* Secondary */
/* color: #543828; => Chocolate */
/* color: #DD715A; => Orange */
/* Accent */
/* color: #EfCf83; => Yellow */

.GurmukhiScriptTuk {
  /* color: #EfCf83; */
  color: #543828;
  font-family: 'Gurbani Akhar Bold';
  font-size: 1.4em;
}

.GurmukhiUnicodeTuk {
  font-family: 'Sant Lipi';
  font-weight: 500;
  color: #543828;
  font-size: 1.6em;
}

.Satluj {
  color: #543828;
  font-family: 'Satluj';
  font-size: 1.4em;
}

/* Dark Mode */
.DarkMode .GurmukhiScriptTuk, .DarkMode .Satluj, .DarkMode .GurmukhiUnicodeTuk {
  color: #F7EBD4;
}

.EnglishTranslit {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  color: #2D2D2F;
  opacity: 0.7;
}

/* Dark Mode */
.DarkMode .EnglishTranslit {
  color: #F7EBD4;
  opacity: 0.7;
}


.PauriTranslation {
  font-family: 'Playfair Display', serif;
  color: #113264;
  font-weight: 500;
  font-size: 1.2em;
}

.Translation {
  font-family: 'Playfair Display', serif;
  color: #113264;
  font-weight: 500;
  font-size: 1.2em;
}


.DarkMode .Translation, .DarkMode .PauriTranslation {
  color: #EFCF83;
}

.TukContainer {
  margin-bottom: 25px;
}

.TukContainer:last-child {
  margin-bottom: 0;
}

.PauriContainer {
  margin-bottom: 2em;
}

@media screen and (min-width: 64em) {
  .PauriContainer {
    margin-bottom: 5em;
  }
}
