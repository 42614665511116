.ToggleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  overflow: hidden;
}

.NoOverflow {
  overflow: hidden;
}

.ToggleCheckbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.ToggleSlot {
  position: relative;
  height: 10em;
  width: 20em;
  border: 5px solid #e4e7ec;
  border-radius: 10em;
  background-color:rgba(255,255,255, 0.4);
  box-shadow: 0px 10px 25px #e4e7ec;
  transition: background-color 250ms;
  zoom: 0.33;
  -moz-transform: scale(0.33);
  -webkit-transform:scale(0.33);
}

.ToggleCheckbox:checked ~ .ToggleSlot {
  background-color: #374151;
  /* background-color: #2F2F2D; */
}

.ToggleButton {
  transform: translate(11.75em, 1.75em);
  position: absolute;
  height: 6.5em;
  width: 6.5em;
  border-radius: 50%;
  background-color: #ffeccf;
  box-shadow: inset 0px 0px 0px 0.75em #EFCF83;
  transition: background-color 250ms, border-color 250ms, transform 500ms cubic-bezier(.26,2,.46,.71);
}

.ToggleCheckbox:checked ~ .ToggleSlot .ToggleButton {
  background-color: #58889C;
  box-shadow: inset 0px 0px 0px 0.75em #F7EBD4;
  transform: translate(1.75em, 1.75em);
}

.SunIcon_Wrapper {
  position: absolute;
  height: 6em;
  width: 6em;
  opacity: 1;
  transform: translate(2em, 2em) rotate(15deg);
  transform-origin: 50% 50%;
  transition: opacity 150ms, transform 500ms cubic-bezier(.26,2,.46,.71);
}

.ToggleCheckbox:checked ~ .ToggleSlot .SunIcon_Wrapper {
  opacity: 0;
  transform: translate(3em, 2em) rotate(0deg);
}

.Moon_Icon_Wrapper {
  position: absolute;
  height: 6em;
  width: 6em;
  opacity: 0;
  transform: translate(11em, 2em) rotate(0deg);
  transform-origin: 50% 50%;
  transition: opacity 150ms, transform 500ms cubic-bezier(.26,2.5,.46,.71);
}

.ToggleCheckbox:checked ~ .ToggleSlot .Moon_Icon_Wrapper {
  opacity: 1;
  transform: translate(12em, 2em) rotate(-15deg);
}


@media screen and (min-width: 40em) {
  .ToggleSlot {
    zoom: 0.25;
    -moz-transform: scale(0.25);
    -webkit-transform:scale(0.25);  
  }
}