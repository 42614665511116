button {
  font-family: 'Josefin Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1.4px;
  line-height: 1;
  border: 0;
  cursor: pointer;
  display: inline-block;
  padding: 1.4em 0;
  width: 49.5%;
}

.Brown {
  background: #543828;
  color: #F7EBD4;
}

.Blue {
  background: #58889C;
  color: #F7EBD4;
}

.Orange {
  background: #DD715A;
  color: #F7EBD4;
}

.BottomRight {
  clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 0 0);
  float: right;
  padding-right: 1em;
}

.BottomLeft {
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 10% 100%, 0 50%);
  float: left;
  padding-left: 1em;
}