.PlayerContainer {
  position: fixed;
  bottom:  0;
  left: 0;
  width: 100%;
  height: 250px;
}

@media screen and (min-width: 40em) {
  .PlayerContainer {
    width: 50%;
    height: 400px;
  }
}
