/* Regular Colour Mode */
.GurmukhiScriptChhandName {
  color: #2D2D2F;
  font-family: 'Gurbani Akhar Black';
  font-size: 1.4em;
  margin: 0;
}

.GurmukhiUnicodeChhandName {
  font-family: 'Sant Lipi';
  color: #2D2D2F;
  font-size: 1.4em;
  margin: 0;
}

.DarkMode .GurmukhiScriptChhandName, .DarkMode .GurmukhiUnicodeChhandName {
color: #DD715A;
}

.ChhandDiv {
  margin-bottom: 15px;
  text-align: left;
}

@media screen and (min-width: 40em) {
  .ChhandDiv:first-child {
    margin-top: 4em;
  }
  /*  Also do the Same for last Pauri */
}