.ChapterContainer {
  margin: 15px 0;
}
.ChapterTitle {
  margin-top: 5px;
  margin-bottom: 0;
  font-family: 'Josefin Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-align: left;
}

.MobileBackButton {
  float: left;
  outline: none;
  border: none;
  background: none;
  margin: 0 0 15px 0;  
}

.MobileBackButton a {
  font-family: 'Josefin Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #DD715A;
}

.MobileTextContainer {
  color: #DD715A;
  margin: 2em 0;
  display: flex;
  flex-direction: column;
  text-align: left;
}

/* Dark Mode */
.DarkMode .MobileTextContainer {
  color: #EB9A98;
}

.ChapterNumberEnglish {
  font-family: 'Josefin Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-align: left;
}

.ImageContainer {
  display: none;
}

.DesktopBackButton {
  position: absolute;
  padding: 0.9375rem;
  outline: none;
  border: none;
  background: #000;
  z-index: 1;
  width: 100px;
  clip-path: polygon(75% 0, 100% 100%, 0 100%, 0 0);
}

.DesktopBackButton a {
  color: #F7EBD4;
  font-family: 'Josefin Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
}

.ChhandContainer {
  padding: 0 0.9375rem;
}

@media screen and (min-width: 40em) {

  .RemoveDesktopPadding {
    padding: 0rem;
    position: relative;
  }

  .ChhandContainer {
    height: 100vh;
    overflow-y: scroll;
    text-align: left;
    padding: 0 2em;
  }

  .ImageContainer {
    display: inherit;
    width: 100%;
    height: 100vh;
    background: #000;
    overflow: hidden;
  }

  .DesktopTextContainer {
    color: #F7EBD4;
  }  

  .DesktopImage {
    object-fit: cover;
    height: 100%;
    opacity: 0.4;
  }

  .DesktopImage.StartTop {
    object-position: top;
  }

  .MobileTextContainer {
    display: none;
  }

  .ChapterTitle {
    margin: 5px 0;
  }

  .DesktopTextContainer {
    padding: 0.9375rem;
    position: absolute;
    top: 40%;
    background: rgba(0, 0, 0, 0.8);
  }
}

@media screen and (min-width: 64em) {
  .DesktopImage {
    width: 100%;
  }

  .ChhandContainer {
    padding: 0 4.5em;
  }

  .DesktopTextContainer {
    width: 50%;

  }
}
