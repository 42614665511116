/* 
==========================
*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background: #F7EBD4;
}

.App.DarkModeApp {
  background: #2D2D2F;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}

.title {
  font-family: 'Playfair Display', serif;
  font-weight: 600;
  font-size: 32px;
  text-align: center;
}

.subtitle {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  text-align: center;
  font-size: 22px;
  letter-spacing: 1.2px;
  text-transform: lowercase;
}

.gurakhar {
  font-family: 'Gurbani Akhar';
}

.gurakhar-bold {
  font-family: 'Gurbani Akhar Bold';
}

.gurakhar-black {
  font-family: 'Gurbani Akhar Black';
}

.satluj {
  font-family: 'Satluj';
}

.satluj-bold {
  font-family: 'Satluj Bold';
}

.mtop15 {
  margin-top: 15px;
}

p {
  margin: 0;
}

button:hover {
  opacity: 0.9;
}

a{
  text-decoration: none;
  display: contents;
}

.unhighlight {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

img {
  box-sizing: border-box;
}
