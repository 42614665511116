.PageTitle {
  text-align: left;
  font-size: 24px;
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  color: #2D2D2F;
}

.DarkMode .PageTitle {
  color: #F7EBD4;
}

@media screen and (min-width: 40em) {
  .PageTitle {
    font-size: 32px;
  }
}