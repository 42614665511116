.ArtworkContainer {
  width: 200px;
  height: 200px;
  position: relative;
}

.TextContainer {
  margin: 20px 15px 0;
  width: 100%;
}


.Artwork {
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.StartTop {
  object-position: top;
}

/* DarkMode */
.DarkMode .Artwork {
  border: 1.5px solid #EFCf83;
  border-bottom-width: 2px;
}

.ItemContainer {
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  margin: 10px;
}

.Circle {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #EFCf83;
  color: #543828;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -16px;
  left: calc(50% - 16px);
  font-family: 'Josefin Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.ChapterNameMain {
  font-family: 'Sant Lipi';
  font-size: 18px;
  color: #543828;
}

/* DarkMode */
.DarkMode .ChapterNameMain {
  color: #F7EBD4;
}

.ChapterNameFaded {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  /* text-align: left; */
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #2D2D2F;
  opacity: 0.9;
}

/* DarkMode */
.DarkMode .ChapterNameFaded {
  color: #F7EBD4;
  opacity: 0.7;
}

@media screen and (min-width: 40em) {
  .ArtworkContainer {
    height: 320px;
    width: 90%;
    max-width: 320px;
    position: relative;
  }
  
}