.Container {
  position: relative;
  /* width: 300px; */
  /* padding: 5px; */

  width: 100%;
  /* height: 40vh; */
  /* min-height: 300px; */
  background: #202c54;
  border-radius: 0 0 2.2rem 2.2rem;
  padding-left: 0;
  padding-right: 0;
}

.Container:before {
  content: "";
  display: block;
  padding-top: 100%;
  /* initial ratio of 1:1*/
}

.Content {
  background: transparent;
  border-radius: 0 0 2.2rem 2.2rem;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Image {
  min-height: 100%;
  min-width: 100%;
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 1px;
  /* For some reason, needs to be set to SOMETHING */
}

.StartTop {
  object-position: top;
}

/* Dark Mode */
.DarkMode .Image {
  border: 1.5px solid #EFCf83;
  border-top: none;
}

.MobileOnly {
  display: none;
}

/* Tablet or Landscape */
@media screen and (min-width: 40em), screen and (orientation:landscape) {
  .MobileOnly {
    display: none;
  }

  .NonMobileOnly {
    display: flex;
  }
}